import React from 'react';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale-subtle.css';

import './index.css';

import Color from 'color';

const SoundButton = ({
  icon,
  soundId,
  onClickPlay,
  tooltipText,
  accent
}) => {
  const accentColor = Color(accent);

  return (
    <Tippy
      content={tooltipText}
      placement="top"
      animation="scale-subtle"
      theme="light"
      arrow={true}
      duration={200}
      delay={[75, 0]}
    >
      <div
        className="sound-button"
        onMouseDown={() => onClickPlay(soundId)}
      >
        <div
          style={{
            backgroundColor: `${accentColor.hex()}`,
            boxShadow: `inset 0 1px 0 ${accentColor.lighten(0.5).hex()}, 0 10px 0 ${accentColor.darken(0.5).hex()}`
          }}
        >
          {icon}
        </div>
      </div>
    </Tippy>
  )
};

export default SoundButton;
