import React from "react";
import "./index.css";
import { trim, chain } from 'lodash';

const ActiveUsers = ({ users }) => {
  const formattedUsers = chain(users)
    .map(user => user.split(' ')[0])
    .value()

  const userCount = users.length;

  const noUsers = userCount <= 0;

  return (
    <div className="active-users">
      <div className="active-users-header">Who Played A Sound</div>
      <div className="active-users-display">
        {
          noUsers ? 'No one.' : `${trim(formattedUsers.join(', '))}`
        }
      </div>
    </div>
  );
};

export default ActiveUsers;
