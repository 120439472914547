import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

const Navigation = () => (
  <div className="app-logo">
    <Link to="/"><span className="logo-text">Bee</span><img className="bee-icon" src="bee.svg" alt="bee womp icon"/><span className="logo-text">Womp</span></Link>
  </div>
)

export default Navigation;
