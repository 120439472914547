import React, { useState, useEffect, Fragment } from 'react';
import useSound from 'use-sound';
import { useLocation, useHistory, Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import './index.css';
import toast from 'react-hot-toast';
import randomMaterialColor from 'random-material-color';
import { uniq, toLower, replace, startCase } from 'lodash';
import Color from 'color';
import { ScaleLoader } from "react-spinners"

import sound_laughtrack from '../../../sounds/laughtrack.mp3';
import sound_boowomp from '../../../sounds/boowomp.mp3';
import sound_whoa from '../../../sounds/whoa.mp3';
import sound_yes from '../../../sounds/yes.mp3';
import sound_nope from '../../../sounds/nope.mp3';
import sound_wow from '../../../sounds/wow.mp3';
import sound_yesomg from '../../../sounds/yesomg.mp3';
import sound_laughtrackmusic from '../../../sounds/laughtrackmusic.mp3';
import sound_myman from '../../../sounds/myman.mp3';
import sound_applause from '../../../sounds/applause.mp3';
import sound_claps from '../../../sounds/claps.mp3';
import sound_brb from '../../../sounds/brb.mp3';
import sound_suspense from '../../../sounds/suspense.mp3';
import sound_incorrect from '../../../sounds/incorrect.mp3';
import sound_partyblower from '../../../sounds/partyblower.mp3';
import sound_nogod from '../../../sounds/nogod.mp3';
import sound_funny from '../../../sounds/funny.mp3';
import sound_patronage from '../../../sounds/patronage.mp3';
import sound_founditem from '../../../sounds/founditem.mp3';
import sound_danger from '../../../sounds/danger.mp3';
import sound_airhorn from '../../../sounds/airhorn.mp3';
import sound_youwhat from '../../../sounds/youwhat.mp3';
import sound_hurt from '../../../sounds/hurt.mp3';
import sound_notfine from '../../../sounds/notfine.mp3';
import sound_legitness from '../../../sounds/legitness.mp3';
import sound_xfiles from '../../../sounds/xfiles.mp3';
import sound_shutupmoney from '../../../sounds/shutupmoney.mp3';
import sound_morecoins from '../../../sounds/morecoins.mp3';
import sound_helloeverybody from '../../../sounds/helloeverybody.mp3';
import sound_helloquestion from '../../../sounds/helloquestion.mp3';
import sound_hellosmall from '../../../sounds/hellosmall.mp3';
import sound_alert from '../../../sounds/alert.mp3';
import sound_quack from '../../../sounds/quack.mp3';
import sound_yay from '../../../sounds/yay.mp3';
import sound_drumroll from '../../../sounds/drumroll.mp3';
import sound_nicememe from '../../../sounds/nicememe.mp3';
import sound_okaybye from '../../../sounds/okaybye.mp3';
import sound_hoorayquestion from '../../../sounds/hoorayquestion.mp3';
import sound_goodbye from '../../../sounds/goodbye.mp3';
import sound_nodog from '../../../sounds/nodog.mp3';
import sound_whatsupdoc from '../../../sounds/whatsupdoc.mp3';
import sound_sorryleaving from '../../../sounds/sorryleaving.mp3';
import sound_hilarious from '../../../sounds/hilarious.mp3';
import sound_byebye from '../../../sounds/byebye.mp3';
import sound_aolgoodbye from '../../../sounds/aolgoodbye.mp3';
import sound_amongus from '../../../sounds/amongus.mp3';
import sound_yesindeed from '../../../sounds/yesindeed.mp3';
import sound_disagree from '../../../sounds/disagree.mp3';
import sound_ofcourse from '../../../sounds/ofcourse.mp3';
import sound_joined from '../../../sounds/joined.mp3';
import sound_dothething from '../../../sounds/dothething.mp3';
import sound_leavemealone from '../../../sounds/leavemealone.mp3';
import sound_youarefakenews from '../../../sounds/youarefakenews.mp3';
import sound_chillinincedarrapids from '../../../sounds/chillinincedarrapids.mp3';
import sound_bruh from '../../../sounds/bruh.mp3';
import sound_canada from '../../../sounds/canada.mp3';
import sound_missionfailed from '../../../sounds/missionfailed.mp3';
import sound_yeet from '../../../sounds/yeet.mp3';
import sound_stopit from '../../../sounds/stopit.mp3';
import sound_bwamp from '../../../sounds/bwamp.mp3';
import sound_thatwaseasy from '../../../sounds/thatwaseasy.mp3';
import sound_enemyspotted from '../../../sounds/enemyspotted.mp3';
import sound_whyareyourunning from '../../../sounds/whyareyourunning.mp3';
import sound_hellodarkness from '../../../sounds/hellodarkness.mp3';
import sound_milk from '../../../sounds/milk.mp3';
import sound_nani from '../../../sounds/nani.mp3';
import sound_garbage from '../../../sounds/garbage.mp3';
import sound_jason from '../../../sounds/jason.mp3';
import sound_curb from '../../../sounds/curb.mp3';
import sound_run from '../../../sounds/run.mp3';
import sound_navihey from '../../../sounds/navihey.mp3';
import sound_punch from '../../../sounds/punch.mp3';
import sound_ohh from '../../../sounds/ohh.mp3';
import sound_careless from '../../../sounds/careless.mp3';
import sound_disturbed from '../../../sounds/disturbed.mp3';
import sound_movealong from '../../../sounds/movealong.mp3';
import sound_gameover from '../../../sounds/gameover.mp3';
import sound_logout from '../../../sounds/logout.mp3';
import sound_turtles from '../../../sounds/turtles.mp3';
import sound_highfive from '../../../sounds/highfive.mp3';
import sound_verynice from '../../../sounds/verynice.mp3';
import sound_messi from '../../../sounds/messi.mp3';
import sound_goodjob from '../../../sounds/goodjob.mp3';
import sound_badumtss from '../../../sounds/badumtss.mp3';
import sound_anotherone from '../../../sounds/anotherone.mp3';
import sound_celebrate from '../../../sounds/celebrate.mp3';
import sound_fieldgoal from '../../../sounds/fieldgoal.mp3';
import sound_cristal from '../../../sounds/cristal.mp3';
// %1

import Footer from '../../footer';
import SoundButton from '../../sound-button';
import SoundButtonSmall from '../../sound-button-small';
import ActiveUsers from '../../active-users';
import VolumeControl from '../../volume-control';
import RoomInfo from '../../room-info';
import { usePrevious } from '../../../utils/hooks';
import { isValidRoomId } from '../../../utils/helpers';

const DEFAULT_SOUND_LENGTH_RULE = 5;

const CLICK_LIMIT = 1;
const CLICK_RESET_MS = 2000;

const getToastStyle = text => ({
  color: `white`,
  backgroundColor: `${randomMaterialColor.getColor({ shades: ['500'] })}`
})

const accents = {
  greeting: [0, 216, 214],
  agreement: [5, 196, 107],
  disagreement: [255, 63, 52],
  farewell: [255, 168, 1],
  acknowledgement: [239, 87, 119],
  celebration: [87, 95, 207],
  shock: [255, 221, 89],
  opportunity: [11, 232, 129],
  humour: [15, 188, 249],
  turmoil: [245, 59, 87],
  soundtrack: [210, 218, 226]
}

const tutorialAccentA = Color(accents.greeting);
const tutorialAccentB = Color(accents.agreement);
const tutorialAccentC = Color(accents.disagreement);
const tutorialAccentD = Color(accents.farewell);

const sounds = {
  'laughtrack': {
    icon: '🤣',
    maxLength: 4,
    tooltipText: 'Laugh Track'
  },
  'boowomp': {
    icon: '😢',
    maxLength: 5,
    tooltipText: 'Sad Womp'
  },
  'whoa': {
    icon: '😮',
    maxLength: 5,
    tooltipText: 'Whoa!'
  },
  'yes': {
    icon: '👍',
    maxLength: 5,
    tooltipText: 'Yes.'
  },
  'nope': {
    icon: '👎',
    maxLength: 5,
    tooltipText: 'Nope.'
  },
  'wow': {
    icon: '🤩',
    maxLength: 5,
    tooltipText: 'Wow!!!'
  },
  'yesomg': {
    icon: '✊',
    maxLength: 5,
    tooltipText: 'YES, OH MY GOSH!'
  },
  'laughtrackmusic': {
    icon: '🤣',
    maxLength: 2,
    tooltipText: 'Laugh Track With Music'
  },
  'myman': {
    icon: '👨‍🦰',
    maxLength: 5,
    tooltipText: 'My man!'
  },
  'applause': {
    icon: '🙌',
    maxLength: 2,
    tooltipText: 'Applause'
  },
  'claps': {
    icon: '👏',
    maxLength: 3,
    tooltipText: 'Claps'
  },
  'brb': {
    icon: '🏃‍♂️',
    maxLength: 2,
    tooltipText: 'We\'ll be right back.'
  },
  'suspense': {
    icon: '😰',
    maxLength: 2,
    tooltipText: 'Suspense Music'
  },
  'incorrect': {
    icon: '❌',
    maxLength: 2,
    tooltipText: 'Incorrect Answer Music'
  },
  'partyblower': {
    icon: '🥳',
    maxLength: 5,
    tooltipText: 'Party Blower'
  },
  'nogod': {
    icon: '😖',
    maxLength: 2,
    tooltipText: 'NO GOD PLEASE NO!'
  },
  'funny': {
    icon: '😄',
    maxLength: 5,
    tooltipText: 'Funny.'
  },
  'patronage': {
    icon: '🫠',
    maxLength: 5,
    tooltipText: 'Thank you for your patronage.'
  },
  'founditem': {
    icon: '📦',
    maxLength: 5,
    tooltipText: 'New Item Discovered!'
  },
  'danger': {
    icon: '⚠️',
    maxLength: 5,
    tooltipText: 'Haha. I\'m In Danger!'
  },
  'airhorn': {
    icon: '📯',
    maxLength: 3,
    tooltipText: 'Airhorn'
  },
  'youwhat': {
    icon: '🤨',
    maxLength: 5,
    tooltipText: 'You WHAT?!'
  },
  'hurt': {
    icon: '🤕',
    maxLength: 5,
    tooltipText: 'Oof.'
  },
  'notfine': {
    icon: '😭',
    maxLength: 2,
    tooltipText: 'Not Doing Fine Music'
  },
  'legitness': {
    icon: '😁',
    maxLength: 5,
    tooltipText: 'That was legitness.'
  },
  'xfiles': {
    icon: '👽',
    maxLength: 2,
    tooltipText: 'Mysterious Alien Music'
  },
  'shutupmoney': {
    icon: '💰',
    maxLength: 5,
    tooltipText: 'Shut up and take my money!'
  },
  'morecoins': {
    icon: '💸',
    maxLength: 5,
    tooltipText: 'Money'
  },
  'helloeverybody': {
    icon: '🙋‍♂️',
    maxLength: 5,
    tooltipText: 'Hello everybody!'
  },
  'helloquestion': {
    icon: '🖐️',
    maxLength: 5,
    tooltipText: 'Hello???'
  },
  'hellosmall': {
    icon: '👋',
    maxLength: 5,
    tooltipText: 'Hello!'
  },
  'alert': {
    icon: '🚨',
    maxLength: 5,
    tooltipText: 'Alert!'
  },
  'quack': {
    icon: '🦆',
    maxLength: 5,
    tooltipText: 'Quack'
  },
  'yay': {
    icon: '🎊',
    maxLength: 3,
    tooltipText: 'YAY!!!'
  },
  'drumroll': {
    icon: '🥁',
    maxLength: 2,
    tooltipText: 'Drumroll'
  },
  'nicememe': {
    icon: '😏',
    maxLength: 5,
    tooltipText: 'Nice meme.'
  },
  'okaybye': {
    icon: '✌️',
    maxLength: 2,
    tooltipText: 'Okay Bye Music'
  },
  'hoorayquestion': {
    icon: '🤷',
    maxLength: 2,
    tooltipText: 'Hooray... question mark?'
  },
  'goodbye': {
    icon: '✌️',
    maxLength: 5,
    tooltipText: 'Goodbye.'
  },
  'nodog': {
    icon: '😑',
    maxLength: 5,
    tooltipText: 'Definitely a no from me dog.'
  },
  'whatsupdoc': {
    icon: '🥕',
    maxLength: 5,
    tooltipText: 'Myeh... what\'s up doc?'
  },
  'sorryleaving': {
    icon: '🚪',
    maxLength: 5,
    tooltipText: 'Sorry, I\'m leaving now.'
  },
  'hilarious': {
    icon: '😂',
    maxLength: 5,
    tooltipText: 'Hilarious laughing.'
  },
  'byebye': {
    icon: '✋',
    maxLength: 5,
    tooltipText: 'Bye bye!'
  },
  'aolgoodbye': {
    icon: '💬',
    maxLength: 5,
    tooltipText: 'Goodbye.'
  },
  'amongus': {
    icon: '🤫',
    maxLength: 5,
    tooltipText: 'Suspicious'
  },
  'yesindeed': {
    icon: '🧐',
    maxLength: 5,
    tooltipText: 'Yes, indeed.'
  },
  'disagree': {
    icon: '🙅',
    maxLength: 5,
    tooltipText: 'I disagree.'
  },
  'ofcourse': {
    icon: '😬',
    maxLength: 5,
    tooltipText: 'OF COURSE!'
  },
  'joined': {
    icon: '🔔',
    maxLength: 5,
    tooltipText: 'User joined.'
  },
  'dothething': {
    icon: '👉',
    maxLength: 5,
    tooltipText: 'Do the thing.'
  },
  'leavemealone': {
    icon: '😖',
    maxLength: 5,
    tooltipText: 'Leave me alone.'
  },
  'youarefakenews': {
    icon: '🙅‍♂️',
    maxLength: 5,
    tooltipText: 'You are fake news.'
  },
  'chillinincedarrapids': {
    icon: '🥶',
    maxLength: 5,
    tooltipText: 'Just chillin in cedar rapids.'
  },
  'bruh': {
    icon: '😩',
    maxLength: 5,
    tooltipText: 'Bruh.'
  },
  'canada': {
    icon: '🦫',
    maxLength: 5,
    tooltipText: 'A message from the Government of Canada.'
  },
  'missionfailed': {
    icon: '😔',
    maxLength: 5,
    tooltipText: 'Mission failed, we\'ll get em next time.'
  },
  'yeet': {
    icon: '😬',
    maxLength: 5,
    tooltipText: 'Yeet!'
  },
  'stopit': {
    icon: '🛑',
    maxLength: 5,
    tooltipText: 'Stop it. Get some help.'
  },
  'bwamp': {
    icon: '💥',
    maxLength: 5,
    tooltipText: 'Bwamp!'
  },
  'thatwaseasy': {
    icon: '😌',
    maxLength: 5,
    tooltipText: 'That was easy.'
  },
  'enemyspotted': {
    icon: '👿',
    maxLength: 5,
    tooltipText: 'Enemy spotted.'
  },
  'whyareyourunning': {
    icon: '🦶',
    maxLength: 5,
    tooltipText: 'Why are you running?'
  },
  'hellodarkness': {
    icon: '🌑',
    maxLength: 5,
    tooltipText: 'Hello darkness my old friend.'
  },
  'milk': {
    icon: '🍼',
    maxLength: 5,
    tooltipText: 'He need some milk.'
  },
  'nani': {
    icon: '😱',
    maxLength: 5,
    tooltipText: 'Omae wa mou sheindeiru. NANI?'
  },
  'garbage': {
    icon: '🗑️',
    maxLength: 5,
    tooltipText: 'I am still a piece of garbage'
  },
  'jason': {
    icon: '🔍',
    maxLength: 5,
    tooltipText: 'Jesus Christ it\'s Jason Bourne.'
  },
  'curb': {
    icon: '🤦🏾‍♂️',
    maxLength: 2,
    tooltipText: 'Curb'
  },
  'run': {
    icon: '🏃‍♀️',
    maxLength: 2,
    tooltipText: 'Run.'
  },
  'navihey': {
    icon: '🙋',
    maxLength: 5,
    tooltipText: 'Hey!'
  },
  'punch': {
    icon: '👊',
    maxLength: 5,
    tooltipText: 'Punch!'
  },
  'ohh': {
    icon: '🙀',
    maxLength: 3,
    tooltipText: 'OHH!'
  },
  'careless': {
    icon: '🎷',
    maxLength: 3,
    tooltipText: 'Sexy saxaphone.'
  },
  'disturbed': {
    icon: '🤘',
    maxLength: 3,
    tooltipText: 'Ooo WAH-AH-AH!'
  },
  'movealong': {
    icon: '🚶‍♂️',
    maxLength: 5,
    tooltipText: 'Move along.'
  },
  'gameover': {
    icon: '🎮',
    maxLength: 5,
    tooltipText: 'Game over.'
  },
  'logout': {
    icon: '💾',
    maxLength: 5,
    tooltipText: 'Logout.'
  },
  'turtles': {
    icon: '🐢',
    maxLength: 5,
    tooltipText: 'I like turtles.'
  },
  'highfive': {
    icon: '🙌',
    maxLength: 5,
    tooltipText: 'High five!'
  },
  'verynice': {
    icon: '🥸',
    maxLength: 5,
    tooltipText: 'Very nice.'
  },
  'messi': {
    icon: '⚽️',
    maxLength: 2,
    tooltipText: 'Ankara Messi!'
  },
  'goodjob': {
    icon: '🤙',
    maxLength: 5,
    tooltipText: 'Good job champ.'
  },
  'badumtss': {
    icon: '😹',
    maxLength: 5,
    tooltipText: 'Ba-dum-tss.'
  },
  'anotherone': {
    icon: '👆',
    maxLength: 5,
    tooltipText: 'Another one.'
  },
  'celebrate': {
    icon: '🎊',
    maxLength: 2,
    tooltipText: 'Celebrate good times.'
  },
  'fieldgoal': {
    icon: '🏈',
    maxLength: 3,
    tooltipText: 'Field goal.'
  },
  'cristal': {
    icon: '🍾',
    maxLength: 3,
    tooltipText: 'Look what I found!'
  }
  // %2
}

const Home = ({ user, firebase, setIsLoading, setLocalConfig, localConfig, isSignedIn }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const hasJoinedRoom = pathname !== '/';
  const roomId        = hasJoinedRoom && pathname.replace('/','')

  const database = firebase.database();

  const username = isSignedIn ? (user.displayName || 'Guest') : null

  const handleUpdateRoomState = ({ roomState, key }) => {
    if (roomState === null) {
      const newRoom = database.ref(`/${key}`);

      newRoom
        .set({
          triggeredBy: username,
          sound:       ''
        })
        .then(() => {});
    }
    setRoomState(roomState);
  }

  const assignRoomStateUpdateEvent = () => {
    const roomRef = database.ref(`/${roomId}`);

    roomRef
      .on('value', snapshot => handleUpdateRoomState({
        key: roomRef.key,
        roomState: snapshot.val()
      }));

    setLastConnectedRoom(roomId);
  }

  useEffect(() => {
    if (roomId && isSignedIn) {
      if (isValidRoomId(roomId)) {
        assignRoomStateUpdateEvent();
        document.title = `Bee Womp | ${roomId}`;
        handleTriggerJoinEvent();
      } else {
        setIsInvalidRoom(true);
      }
    } else {
      if (lastConnectedRoom && pathname === '/') {
        const roomRef = database.ref(`/${lastConnectedRoom}`);

        roomRef.off('value');
        setLastConnectedRoom(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isSignedIn])

  // useEffect(() => {
  //   setIsLoggingIn(false);
  // }, [isSignedIn])

  const [activeSounds, setActiveSounds]           = useState([]);
  const [roomToJoin, setRoomToJoin]               = useState('');
  const [roomState, setRoomState]                 = useState({});
  const [volume, setVolume]                       = useState(localConfig.volume);
  const [isInvalidRoom, setIsInvalidRoom]         = useState(false);
  const [clicks, setClicks]                       = useState(0);
  const [users, setUsers]                         = useState([]);
  const [isLoggingIn, setIsLoggingIn]             = useState(false);
  const [lastConnectedRoom, setLastConnectedRoom] = useState(null);

  const previousRoomState    = usePrevious(roomState);
  const previousActiveSounds = usePrevious(activeSounds);

  useEffect(() => {
    const soundHasBeenTriggered = previousRoomState !== roomState
    const canExtractId          = roomState?.sound && roomState.sound.includes('_')

    if (soundHasBeenTriggered && canExtractId) {
      const [soundId, timeStamp] = roomState?.sound?.split('_')
      const { triggeredBy }      = roomState

      const fiveSecondsAgo = moment().subtract(5, 'seconds')
      const whenTriggered  = moment(Number(timeStamp))

      if (triggeredBy && soundId && whenTriggered.isAfter(fiveSecondsAgo)) {
        toast(
          triggeredBy,
          {
            icon:  sounds[soundId].icon || '🐝',
            style: getToastStyle(username)
          }
        );

        setUsers(uniq([...users, triggeredBy]))

        setActiveSounds(currentActiveSounds => {
          if (currentActiveSounds && currentActiveSounds.filter(s => s === soundId).length < (sounds[soundId].maxLength || DEFAULT_SOUND_LENGTH_RULE)) {
            return [...currentActiveSounds, soundId]
          }
        });
      }
    }
  }, [previousRoomState, roomState])

  const handleChangeRoomToJoin = ({ target: { value: updatedRoomToJoin } }) => {
    const formattedRoomName = replace(toLower(updatedRoomToJoin), ' ', '-')

    setRoomToJoin(formattedRoomName);
  }

  const handleClickJoinRoom = () => {
    setIsLoading(true);
    window.location.assign(`/${roomToJoin}`);
  }

  const resetClicks = () => {
    setTimeout(() => {
      setClicks(0)
    }, CLICK_RESET_MS)
  }

  useEffect(() => {
    if (clicks >= CLICK_LIMIT) {
      resetClicks();
    }
  }, [clicks])

  const handleOnClickPlay = soundId => {
    if (clicks < CLICK_LIMIT) {
      setClicks(clicks => clicks + 1);

      const stateRef = database.ref(`/${roomId}`);

      stateRef
        .set({
          triggeredBy: username,
          sound:       `${soundId}_${Date.now()}`
        })
        .then(() => {
          // console.log(`Setting sound: ${soundId}`)
        })
    }
  }

  const handleTriggerJoinEvent = () => {
    const soundId = 'joined';
    const stateRef = database.ref(`/${roomId}`);

    stateRef
      .set({
        triggeredBy: username,
        sound:       `${soundId}_${Date.now()}`
      })
      .then(() => {
        // console.log(`Setting sound: ${soundId}`)
      })
  }

  const handleOnEnd = soundId => {
    setActiveSounds(currentActiveSounds => {
      let foundItem = false;
      let updatedActiveSounds = []

      if (currentActiveSounds) {
        for (var i = 0; i < currentActiveSounds.length; i++) {
          if (currentActiveSounds[i] === soundId && foundItem === false) {
            foundItem = true;
          } else {
            updatedActiveSounds = [...updatedActiveSounds, currentActiveSounds[i]]
          }
        }
      }

      return updatedActiveSounds
    });
  }

  const handleChangeVolume = value => {
    setLocalConfig({ ...localConfig, volume: Number(value) });
    setVolume(Number(value));
  }

  const handleClickLogout = () => {
    firebase.auth().signOut();
    history.push('/');
  }

  const handleClickLoginGoogle = () => {
    setIsLoggingIn(true);
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(googleAuthProvider)
      .then(() => {
        setIsLoggingIn(false);
      })
      .catch(() => {
        console.log('Google login unsuccessful.')
      });
  }

  const handleClickLoginGuest = () => {
    setIsLoggingIn(true);
    firebase.auth().signInAnonymously()
      .then(() => {
        setIsLoggingIn(false);
      })
      .catch(() => {
        console.log('Anonymous login unsuccessful.')
      });
  }

  const adjustedVolume = volume / 100;

  const [play_laughtrack] = useSound(sound_laughtrack, { volume: adjustedVolume, onend: () => handleOnEnd('laughtrack') });
  const [play_boowomp] = useSound(sound_boowomp, { volume: adjustedVolume, onend: () => handleOnEnd('boowomp') });
  const [play_whoa] = useSound(sound_whoa, { volume: adjustedVolume, onend: () => handleOnEnd('whoa') });
  const [play_yes] = useSound(sound_yes, { volume: adjustedVolume, onend: () => handleOnEnd('yes') });
  const [play_nope] = useSound(sound_nope, { volume: adjustedVolume, onend: () => handleOnEnd('nope') });
  const [play_wow] = useSound(sound_wow, { volume: adjustedVolume, onend: () => handleOnEnd('wow') });
  const [play_yesomg] = useSound(sound_yesomg, { volume: adjustedVolume, onend: () => handleOnEnd('yesomg') });
  const [play_laughtrackmusic] = useSound(sound_laughtrackmusic, { volume: adjustedVolume, onend: () => handleOnEnd('laughtrackmusic') });
  const [play_myman] = useSound(sound_myman, { volume: adjustedVolume, onend: () => handleOnEnd('myman') });
  const [play_applause] = useSound(sound_applause, { volume: adjustedVolume, onend: () => handleOnEnd('applause') });
  const [play_claps] = useSound(sound_claps, { volume: adjustedVolume, onend: () => handleOnEnd('claps') });
  const [play_brb] = useSound(sound_brb, { volume: adjustedVolume, onend: () => handleOnEnd('brb') });
  const [play_suspense] = useSound(sound_suspense, { volume: adjustedVolume, onend: () => handleOnEnd('suspense') });
  const [play_incorrect] = useSound(sound_incorrect, { volume: adjustedVolume, onend: () => handleOnEnd('incorrect') });
  const [play_partyblower] = useSound(sound_partyblower, { volume: adjustedVolume, onend: () => handleOnEnd('partyblower') });
  const [play_nogod] = useSound(sound_nogod, { volume: adjustedVolume, onend: () => handleOnEnd('nogod') });
  const [play_funny] = useSound(sound_funny, { volume: adjustedVolume, onend: () => handleOnEnd('funny') });
  const [play_patronage] = useSound(sound_patronage, { volume: adjustedVolume, onend: () => handleOnEnd('patronage') });
  const [play_founditem] = useSound(sound_founditem, { volume: adjustedVolume, onend: () => handleOnEnd('founditem') });
  const [play_danger] = useSound(sound_danger, { volume: adjustedVolume, onend: () => handleOnEnd('danger') });
  const [play_airhorn] = useSound(sound_airhorn, { volume: adjustedVolume, onend: () => handleOnEnd('airhorn') });
  const [play_youwhat] = useSound(sound_youwhat, { volume: adjustedVolume, onend: () => handleOnEnd('youwhat') });
  const [play_hurt] = useSound(sound_hurt, { volume: adjustedVolume, onend: () => handleOnEnd('hurt') });
  const [play_notfine] = useSound(sound_notfine, { volume: adjustedVolume, onend: () => handleOnEnd('notfine') });
  const [play_legitness] = useSound(sound_legitness, { volume: adjustedVolume, onend: () => handleOnEnd('legitness') });
  const [play_xfiles] = useSound(sound_xfiles, { volume: adjustedVolume, onend: () => handleOnEnd('xfiles') });
  const [play_shutupmoney] = useSound(sound_shutupmoney, { volume: adjustedVolume, onend: () => handleOnEnd('shutupmoney') });
  const [play_morecoins] = useSound(sound_morecoins, { volume: adjustedVolume, onend: () => handleOnEnd('morecoins') });
  const [play_helloeverybody] = useSound(sound_helloeverybody, { volume: adjustedVolume, onend: () => handleOnEnd('helloeverybody') });
  const [play_helloquestion] = useSound(sound_helloquestion, { volume: adjustedVolume, onend: () => handleOnEnd('helloquestion') });
  const [play_hellosmall] = useSound(sound_hellosmall, { volume: adjustedVolume, onend: () => handleOnEnd('hellosmall') });
  const [play_alert] = useSound(sound_alert, { volume: adjustedVolume, onend: () => handleOnEnd('alert') });
  const [play_quack] = useSound(sound_quack, { volume: adjustedVolume, onend: () => handleOnEnd('quack') });
  const [play_yay] = useSound(sound_yay, { volume: adjustedVolume, onend: () => handleOnEnd('yay') });
  const [play_drumroll] = useSound(sound_drumroll, { volume: adjustedVolume, onend: () => handleOnEnd('drumroll') });
  const [play_nicememe] = useSound(sound_nicememe, { volume: adjustedVolume, onend: () => handleOnEnd('nicememe') });
  const [play_okaybye] = useSound(sound_okaybye, { volume: adjustedVolume, onend: () => handleOnEnd('okaybye') });
  const [play_hoorayquestion] = useSound(sound_hoorayquestion, { volume: adjustedVolume, onend: () => handleOnEnd('hoorayquestion') });
  const [play_goodbye] = useSound(sound_goodbye, { volume: adjustedVolume, onend: () => handleOnEnd('goodbye') });
  const [play_nodog] = useSound(sound_nodog, { volume: adjustedVolume, onend: () => handleOnEnd('nodog') });
  const [play_whatsupdoc] = useSound(sound_whatsupdoc, { volume: adjustedVolume, onend: () => handleOnEnd('whatsupdoc') });
  const [play_sorryleaving] = useSound(sound_sorryleaving, { volume: adjustedVolume, onend: () => handleOnEnd('sorryleaving') });
  const [play_hilarious] = useSound(sound_hilarious, { volume: adjustedVolume, onend: () => handleOnEnd('hilarious') });
  const [play_byebye] = useSound(sound_byebye, { volume: adjustedVolume, onend: () => handleOnEnd('byebye') });
  const [play_aolgoodbye] = useSound(sound_aolgoodbye, { volume: adjustedVolume, onend: () => handleOnEnd('aolgoodbye') });
  const [play_amongus] = useSound(sound_amongus, { volume: adjustedVolume, onend: () => handleOnEnd('amongus') });
  const [play_yesindeed] = useSound(sound_yesindeed, { volume: adjustedVolume, onend: () => handleOnEnd('yesindeed') });
  const [play_disagree] = useSound(sound_disagree, { volume: adjustedVolume, onend: () => handleOnEnd('disagree') });
  const [play_ofcourse] = useSound(sound_ofcourse, { volume: adjustedVolume, onend: () => handleOnEnd('ofcourse') });
  const [play_joined] = useSound(sound_joined, { volume: adjustedVolume, onend: () => handleOnEnd('joined') });
  const [play_dothething] = useSound(sound_dothething, { volume: adjustedVolume, onend: () => handleOnEnd('dothething') });
  const [play_leavemealone] = useSound(sound_leavemealone, { volume: adjustedVolume, onend: () => handleOnEnd('leavemealone') });
  const [play_youarefakenews] = useSound(sound_youarefakenews, { volume: adjustedVolume, onend: () => handleOnEnd('youarefakenews') });
  const [play_chillinincedarrapids] = useSound(sound_chillinincedarrapids, { volume: adjustedVolume, onend: () => handleOnEnd('chillinincedarrapids') });
  const [play_bruh] = useSound(sound_bruh, { volume: adjustedVolume, onend: () => handleOnEnd('bruh') });
  const [play_canada] = useSound(sound_canada, { volume: adjustedVolume, onend: () => handleOnEnd('canada') });
  const [play_missionfailed] = useSound(sound_missionfailed, { volume: adjustedVolume, onend: () => handleOnEnd('missionfailed') });
  const [play_yeet] = useSound(sound_yeet, { volume: adjustedVolume, onend: () => handleOnEnd('yeet') });
  const [play_stopit] = useSound(sound_stopit, { volume: adjustedVolume, onend: () => handleOnEnd('stopit') });
  const [play_bwamp] = useSound(sound_bwamp, { volume: adjustedVolume, onend: () => handleOnEnd('bwamp') });
  const [play_thatwaseasy] = useSound(sound_thatwaseasy, { volume: adjustedVolume, onend: () => handleOnEnd('thatwaseasy') });
  const [play_enemyspotted] = useSound(sound_enemyspotted, { volume: adjustedVolume, onend: () => handleOnEnd('enemyspotted') });
  const [play_whyareyourunning] = useSound(sound_whyareyourunning, { volume: adjustedVolume, onend: () => handleOnEnd('whyareyourunning') });
  const [play_hellodarkness] = useSound(sound_hellodarkness, { volume: adjustedVolume, onend: () => handleOnEnd('hellodarkness') });
  const [play_milk] = useSound(sound_milk, { volume: adjustedVolume, onend: () => handleOnEnd('milk') });
  const [play_nani] = useSound(sound_nani, { volume: adjustedVolume, onend: () => handleOnEnd('nani') });
  const [play_garbage] = useSound(sound_garbage, { volume: adjustedVolume, onend: () => handleOnEnd('garbage') });
  const [play_jason] = useSound(sound_jason, { volume: adjustedVolume, onend: () => handleOnEnd('jason') });
  const [play_curb] = useSound(sound_curb, { volume: adjustedVolume, onend: () => handleOnEnd('curb') });
  const [play_run] = useSound(sound_run, { volume: adjustedVolume, onend: () => handleOnEnd('run') });
  const [play_navihey] = useSound(sound_navihey, { volume: adjustedVolume, onend: () => handleOnEnd('navihey') });
  const [play_punch] = useSound(sound_punch, { volume: adjustedVolume, onend: () => handleOnEnd('punch') });
  const [play_ohh] = useSound(sound_ohh, { volume: adjustedVolume, onend: () => handleOnEnd('ohh') });
  const [play_careless] = useSound(sound_careless, { volume: adjustedVolume, onend: () => handleOnEnd('careless') });
  const [play_disturbed] = useSound(sound_disturbed, { volume: adjustedVolume, onend: () => handleOnEnd('disturbed') });
  const [play_movealong] = useSound(sound_movealong, { volume: adjustedVolume, onend: () => handleOnEnd('movealong') });
  const [play_gameover] = useSound(sound_gameover, { volume: adjustedVolume, onend: () => handleOnEnd('gameover') });
  const [play_logout] = useSound(sound_logout, { volume: adjustedVolume, onend: () => handleOnEnd('logout') });
  const [play_turtles] = useSound(sound_turtles, { volume: adjustedVolume, onend: () => handleOnEnd('turtles') });
  const [play_highfive] = useSound(sound_highfive, { volume: adjustedVolume, onend: () => handleOnEnd('highfive') });
  const [play_verynice] = useSound(sound_verynice, { volume: adjustedVolume, onend: () => handleOnEnd('verynice') });
  const [play_messi] = useSound(sound_messi, { volume: adjustedVolume, onend: () => handleOnEnd('messi') });
  const [play_goodjob] = useSound(sound_goodjob, { volume: adjustedVolume, onend: () => handleOnEnd('goodjob') });
  const [play_badumtss] = useSound(sound_badumtss, { volume: adjustedVolume, onend: () => handleOnEnd('badumtss') });
  const [play_anotherone] = useSound(sound_anotherone, { volume: adjustedVolume, onend: () => handleOnEnd('anotherone') });
  const [play_celebrate] = useSound(sound_celebrate, { volume: adjustedVolume, onend: () => handleOnEnd('celebrate') });
  const [play_fieldgoal] = useSound(sound_fieldgoal, { volume: adjustedVolume, onend: () => handleOnEnd('fieldgoal') });
  const [play_cristal] = useSound(sound_cristal, { volume: adjustedVolume, onend: () => handleOnEnd('cristal') });
  // %3

  const lastSoundTriggered = activeSounds && activeSounds[activeSounds.length - 1]

  const canPlaySound = `${activeSounds}` !== `${previousActiveSounds}`
    && lastSoundTriggered
    && activeSounds.filter(
      soundId => soundId === lastSoundTriggered
    ).length < (sounds[lastSoundTriggered].maxLength || DEFAULT_SOUND_LENGTH_RULE)
    && previousActiveSounds.filter(s => s === lastSoundTriggered).length < activeSounds.filter(s => s === lastSoundTriggered).length

  if (canPlaySound) {
    if (lastSoundTriggered === 'laughtrack') play_laughtrack();
    if (lastSoundTriggered === 'boowomp') play_boowomp();
    if (lastSoundTriggered === 'whoa') play_whoa();
    if (lastSoundTriggered === 'yes') play_yes();
    if (lastSoundTriggered === 'nope') play_nope();
    if (lastSoundTriggered === 'wow') play_wow();
    if (lastSoundTriggered === 'yesomg') play_yesomg();
    if (lastSoundTriggered === 'laughtrackmusic') play_laughtrackmusic();
    if (lastSoundTriggered === 'myman') play_myman();
    if (lastSoundTriggered === 'applause') play_applause();
    if (lastSoundTriggered === 'claps') play_claps();
    if (lastSoundTriggered === 'brb') play_brb();
    if (lastSoundTriggered === 'suspense') play_suspense();
    if (lastSoundTriggered === 'incorrect') play_incorrect();
    if (lastSoundTriggered === 'partyblower') play_partyblower();
    if (lastSoundTriggered === 'nogod') play_nogod();
    if (lastSoundTriggered === 'funny') play_funny();
    if (lastSoundTriggered === 'patronage') play_patronage();
    if (lastSoundTriggered === 'founditem') play_founditem();
    if (lastSoundTriggered === 'danger') play_danger();
    if (lastSoundTriggered === 'airhorn') play_airhorn();
    if (lastSoundTriggered === 'youwhat') play_youwhat();
    if (lastSoundTriggered === 'hurt') play_hurt();
    if (lastSoundTriggered === 'notfine') play_notfine();
    if (lastSoundTriggered === 'legitness') play_legitness();
    if (lastSoundTriggered === 'xfiles') play_xfiles();
    if (lastSoundTriggered === 'shutupmoney') play_shutupmoney();
    if (lastSoundTriggered === 'morecoins') play_morecoins();
    if (lastSoundTriggered === 'helloeverybody') play_helloeverybody();
    if (lastSoundTriggered === 'helloquestion') play_helloquestion();
    if (lastSoundTriggered === 'hellosmall') play_hellosmall();
    if (lastSoundTriggered === 'alert') play_alert();
    if (lastSoundTriggered === 'quack') play_quack();
    if (lastSoundTriggered === 'yay') play_yay();
    if (lastSoundTriggered === 'drumroll') play_drumroll();
    if (lastSoundTriggered === 'nicememe') play_nicememe();
    if (lastSoundTriggered === 'okaybye') play_okaybye();
    if (lastSoundTriggered === 'hoorayquestion') play_hoorayquestion();
    if (lastSoundTriggered === 'goodbye') play_goodbye();
    if (lastSoundTriggered === 'nodog') play_nodog();
    if (lastSoundTriggered === 'whatsupdoc') play_whatsupdoc();
    if (lastSoundTriggered === 'sorryleaving') play_sorryleaving();
    if (lastSoundTriggered === 'hilarious') play_hilarious();
    if (lastSoundTriggered === 'byebye') play_byebye();
    if (lastSoundTriggered === 'aolgoodbye') play_aolgoodbye();
    if (lastSoundTriggered === 'amongus') play_amongus();
    if (lastSoundTriggered === 'yesindeed') play_yesindeed();
    if (lastSoundTriggered === 'disagree') play_disagree();
    if (lastSoundTriggered === 'ofcourse') play_ofcourse();
    if (lastSoundTriggered === 'joined') play_joined();
    if (lastSoundTriggered === 'dothething') play_dothething();
    if (lastSoundTriggered === 'leavemealone') play_leavemealone();
    if (lastSoundTriggered === 'youarefakenews') play_youarefakenews();
    if (lastSoundTriggered === 'chillinincedarrapids') play_chillinincedarrapids();
    if (lastSoundTriggered === 'bruh') play_bruh();
    if (lastSoundTriggered === 'canada') play_canada();
    if (lastSoundTriggered === 'missionfailed') play_missionfailed();
    if (lastSoundTriggered === 'yeet') play_yeet();
    if (lastSoundTriggered === 'stopit') play_stopit();
    if (lastSoundTriggered === 'bwamp') play_bwamp();
    if (lastSoundTriggered === 'thatwaseasy') play_thatwaseasy();
    if (lastSoundTriggered === 'enemyspotted') play_enemyspotted();
    if (lastSoundTriggered === 'whyareyourunning') play_whyareyourunning();
    if (lastSoundTriggered === 'hellodarkness') play_hellodarkness();
    if (lastSoundTriggered === 'milk') play_milk();
    if (lastSoundTriggered === 'nani') play_nani();
    if (lastSoundTriggered === 'garbage') play_garbage();
    if (lastSoundTriggered === 'jason') play_jason();
    if (lastSoundTriggered === 'curb') play_curb();
    if (lastSoundTriggered === 'run') play_run();
    if (lastSoundTriggered === 'navihey') play_navihey();
    if (lastSoundTriggered === 'punch') play_punch();
    if (lastSoundTriggered === 'ohh') play_ohh();
    if (lastSoundTriggered === 'careless') play_careless();
    if (lastSoundTriggered === 'disturbed') play_disturbed();
    if (lastSoundTriggered === 'movealong') play_movealong();
    if (lastSoundTriggered === 'gameover') play_gameover();
    if (lastSoundTriggered === 'logout') play_logout();
    if (lastSoundTriggered === 'turtles') play_turtles();
    if (lastSoundTriggered === 'highfive') play_highfive();
    if (lastSoundTriggered === 'verynice') play_verynice();
    if (lastSoundTriggered === 'messi') play_messi();
    if (lastSoundTriggered === 'goodjob') play_goodjob();
    if (lastSoundTriggered === 'badumtss') play_badumtss();
    if (lastSoundTriggered === 'anotherone') play_anotherone();
    if (lastSoundTriggered === 'celebrate') play_celebrate();
    if (lastSoundTriggered === 'fieldgoal') play_fieldgoal();
    if (lastSoundTriggered === 'cristal') play_cristal();
    // %4
  }

  const canClickJoinRoom = roomToJoin !== '' && roomToJoin.length <= 30
  const roomNameTooLong = roomToJoin && roomToJoin.length > 30

  if (isSignedIn && hasJoinedRoom && !isInvalidRoom) {
    return (
      <div className="page-home">
        <div className="page-home-container">
          <div className="sound-button-area">
            <div className="sound-buttons">
              <SoundGroup category="greeting" accent={accents.greeting}>
                {
                  [
                    'hellosmall',
                    'helloquestion',
                    'helloeverybody',
                    'whatsupdoc'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.greeting}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="agreement" accent={accents.agreement}>
                {
                  [
                    'yes',
                    'yesomg',
                    'yesindeed',
                    'ofcourse'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.agreement}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="disagreement" accent={accents.disagreement}>
                {
                  [
                    'nope',
                    'nogod',
                    'nodog',
                    'disagree'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.disagreement}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="farewell" accent={accents.farewell}>
                {
                  [
                    'goodbye',
                    'aolgoodbye',
                    'byebye',
                    'sorryleaving'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.farewell}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="acknowledgement" accent={accents.acknowledgement}>
                {
                  [
                    'myman',
                    'claps',
                    'applause',
                    'patronage'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.acknowledgement}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="celebration" accent={accents.celebration}>
                {
                  [
                    'wow',
                    'partyblower',
                    'airhorn',
                    'legitness'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.celebration}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="shock" accent={accents.shock}>
                {
                  [
                    'youwhat',
                    'whoa',
                    'alert',
                    'quack'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.shock}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="opportunity" accent={accents.opportunity}>
                {
                  [
                    'founditem',
                    'morecoins',
                    'shutupmoney',
                    'drumroll'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.opportunity}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="humour" accent={accents.humour}>
                {
                  [
                    'funny',
                    'laughtrack',
                    'nicememe',
                    'hilarious'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.humour}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="turmoil" accent={accents.turmoil}>
                {
                  [
                    'hurt',
                    'danger',
                    'hoorayquestion',
                    'boowomp'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.turmoil}
                    />
                  ))
                }
              </SoundGroup>
              <SoundGroup category="soundtrack" accent={accents.soundtrack} width="320px">
                {
                  [
                    'laughtrackmusic',
                    'notfine',
                    'okaybye',
                    'brb',
                    'incorrect',
                    'xfiles',
                    'suspense',
                    'amongus'
                  ].map(soundId => (soundId !== '' &&
                    <SoundButton
                      key={soundId}
                      soundId={soundId}
                      activeSounds={activeSounds}
                      icon={sounds[soundId].icon || `${soundId}`}
                      onEnd={handleOnEnd}
                      onClickPlay={handleOnClickPlay}
                      tooltipText={sounds[soundId].tooltipText}
                      accent={accents.soundtrack}
                    />
                  ))
                }
              </SoundGroup>
            </div>
            <div className="sound-group-category category-centered">
              <div>Extras</div>
            </div>
            <div className="sound-button-extras">
              {
                [
                  'dothething',
                  'leavemealone',
                  'youarefakenews',
                  'chillinincedarrapids',
                  'bruh',
                  'canada',
                  'missionfailed',
                  'yeet',
                  'stopit',
                  'bwamp',
                  'thatwaseasy',
                  'enemyspotted',
                  'whyareyourunning',
                  'hellodarkness',
                  'milk',
                  'nani',
                  'garbage',
                  'jason',
                  'curb',
                  'run',
                  'navihey',
                  'punch',
                  'ohh',
                  'careless',
                  'disturbed',
                  'movealong',
                  'gameover',
                  'logout',
                  'turtles',
                  'highfive',
                  'verynice',
                  'messi',
                  'goodjob',
                  'badumtss',
                  'anotherone',
                  'celebrate',
                  'fieldgoal',
                  'cristal'
                ].map(soundId => (soundId !== '' &&
                  <SoundButtonSmall
                    key={soundId}
                    soundId={soundId}
                    activeSounds={activeSounds}
                    icon={sounds[soundId].icon || `${soundId}`}
                    onEnd={handleOnEnd}
                    onClickPlay={handleOnClickPlay}
                    tooltipText={sounds[soundId].tooltipText}
                  />
                ))
              }
            </div>
          </div>
          <div className="settings-area">
            <div className="settings">
              <div className="settings-section">
                <RoomInfo
                  roomId={roomId}
                  username={username}
                  firebase={firebase}
                />
              </div>
              <div className="settings-section">
                <VolumeControl
                  volume={volume}
                  onChangeVolume={handleChangeVolume}
                />
                <ActiveUsers users={users} />
              </div>
            </div>
            {/*
            <div className="banner-ad-area">
              [BANNER_AD]
            </div>
            */}
          </div>
          <div className="about-area">
            <Footer firebase={firebase} user={user} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="page-home">
        <div className="page-home-lobby-container">
          <div className="page-home-col">
            <div className="page-home-tutorial-header">
              What is Bee Womp? 🤔
            </div>
            <div className="explanation explanation-1">
              <div className="explanation-1-box">
                <div className="explanation-1-shape-1">
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.greeting).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.greeting).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.agreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.agreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.disagreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.disagreement).hex() }}></div>

                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.greeting).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.greeting).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.agreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.agreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.disagreement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.disagreement).hex() }}></div>

                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.farewell).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.farewell).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.acknowledgement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.acknowledgement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.celebration).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.celebration).hex() }}></div>

                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.farewell).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.farewell).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.acknowledgement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.acknowledgement).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.celebration).hex() }}></div>
                  <div className="explanation-1-shape-1-a" style={{ backgroundColor: Color(accents.celebration).hex() }}></div>
                </div>
                <div className="explanation-1-shape-2">
                  <div className="explanation-1-shape-2-a">
                    <div className="explanation-1-shape-2-a-a"></div>
                    <div className="explanation-1-shape-2-a-a"></div>
                    <div className="explanation-1-shape-2-a-a"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-home-explanation">
              Bee Womp is a <span className="emphasis">shareable live soundboard</span> you can use to play fun reaction sounds during video calls with friends or co-workers.
            </div>
            <div className="explanation">
              <div className="explanation-2-box">
                <div className="explanation-2-shape-1">
                  <div className="explanation-2-shape-1-a">
                    <div className="explanation-2-shape-1-a-a explanation-2-shape-1-a-a-active">😀</div>
                  </div>
                  <div className="explanation-2-shape-1-a">
                    <div className="explanation-2-shape-1-a-a">😆</div>
                  </div>
                  <div className="explanation-2-shape-1-a">
                    <div className="explanation-2-shape-1-a-a">😅</div>
                  </div>
                  <div className="explanation-2-shape-1-a">
                    <div className="explanation-2-shape-1-a-a">🙂</div>
                  </div>
                </div>
                <div className="explanation-2-shape-2">
                  <div className="explanation-2-shape-2-a"></div>
                </div>
              </div>
            </div>
            <div className="page-home-explanation">
              It provides a <span className="emphasis">safe-for-work</span> set of popular meme sounds that are perfect for any meeting. Just leave it open in a tab or browser window.
            </div>
            <div className="page-home-explanation-divider"></div>
            <div className="page-home-explanation-parody">* Bee Womp is a parody app of <a href="https://www.fullstory.com/blog/fun-virtual-team-meeting/">BWAMP by FullStory</a></div>
          </div>
          <div className="page-home-col">
            <div className="page-home-tutorial-header">
              How Do You Use It? 😮
            </div>
            <ol className="page-home-how-to-list">
              <li className="page-home-how-to-item">
                1. Create or join an existing soundboard session.
                <div className="tutorial">
                  <div className="tutorial-1-box">
                    <div className="tutorial-1-shape-1"></div>
                    <div className="tutorial-1-shape-2">
                      <div className="tutorial-1-shape-2-a"></div>
                    </div>
                    <div className="tutorial-1-shape-3">
                      Connect
                    </div>
                  </div>
                </div>
              </li>
              <li className="page-home-how-to-item">
                2. Share the session URL with users who want to join.
                <div className="tutorial">
                  <div className="tutorial-2-box">
                    <div className="tutorial-2-shape-1"></div>
                    <div className="tutorial-2-shape-2">
                      <span className="faded-display">BEEWOMP.ME/</span>ROOM
                    </div>
                  </div>
                </div>
              </li>
              <li className="page-home-how-to-item">
                3. Click buttons to play sounds for everyone.
                <div className="tutorial">
                  <div className="tutorial-3-box">
                    <div className="tutorial-3-shape-1">
                      <div
                        style={{
                          backgroundColor: `${tutorialAccentA.hex()}`,
                          boxShadow: `inset 0 1px 0 ${tutorialAccentA.lighten(0.5).hex()}, 0 10px 0 ${tutorialAccentA.darken(0.5).hex()}`
                        }}
                      >🤣</div>
                    </div>
                    <div className="tutorial-3-shape-1">
                      <div
                        style={{
                          backgroundColor: `${tutorialAccentB.hex()}`,
                          boxShadow: `inset 0 1px 0 ${tutorialAccentB.lighten(0.5).hex()}, 0 10px 0 ${tutorialAccentB.darken(0.5).hex()}`
                        }}
                      >😢</div>
                    </div>
                    <div className="tutorial-3-shape-1">
                      <div
                        style={{
                          backgroundColor: `${tutorialAccentC.hex()}`,
                          boxShadow: `inset 0 1px 0 ${tutorialAccentC.lighten(0.5).hex()}, 0 10px 0 ${tutorialAccentC.darken(0.5).hex()}`
                        }}
                      >😮</div>
                    </div>
                    <div className="tutorial-3-shape-1">
                      <div
                        style={{
                          backgroundColor: `${tutorialAccentD.hex()}`,
                          boxShadow: `inset 0 1px 0 ${tutorialAccentD.lighten(0.5).hex()}, 0 10px 0 ${tutorialAccentD.darken(0.5).hex()}`
                        }}
                      >👍</div>
                    </div>
                  </div>
                </div>
              </li>
            </ol>
          </div>
          <div className="page-home-col">
            <div className="page-home-tutorial-header">
              Getting Started 😊
            </div>
            <div className="page-home-lobby-join-container">
              {isLoggingIn ? (
                <ScaleLoader size={20} margin={5} color="#ffc048" />
              ) : (
                isSignedIn ? (
                  <Fragment>
                    <div className="page-home-lobby-header">
                      Join or create a live soundboard session:
                    </div>
                    <input
                      className="page-home-join-room-input"
                      type="text"
                      value={roomToJoin}
                      placeholder={'Enter session name.'}
                      onChange={handleChangeRoomToJoin}
                      onKeyDown={canClickJoinRoom ? event => event.key === 'Enter' && handleClickJoinRoom() : () => {}}
                      autoFocus
                    />
                    <div
                      className={`page-home-join-room-btn${canClickJoinRoom ? '' : '-disabled'}`}
                      onClick={canClickJoinRoom ? () => handleClickJoinRoom() : () => {}}
                    >
                      {roomNameTooLong ? 'Name Too Long' : 'Connect'}
                    </div>
                    <div className="page-home-join-desc">All sessions are unlisted public rooms. Pick a name that is unique to your group or organization.</div>
                    <div className="page-home-join-desc-divider"></div>
                    <div className="page-home-join-user-info">Logged in as <span className="page-home-join-username">{username}</span></div>
                    <div
                      className="page-home-join-user-logout-btn"
                      onClick={handleClickLogout}
                    >
                      Logout
                    </div>
                  </Fragment>
                ) : (
                  <div className="page-home-sign-in-options">
                    <div className="page-home-sign-in-options-desc">Choose a sign-in option to continue:</div>
                    <div
                      className="page-home-sign-in-google"
                      onClick={handleClickLoginGoogle}
                    >
                    </div>
                    <div className="page-home-sign-in-or">or</div>
                    <div
                      className="page-home-sign-in-guest"
                      onClick={handleClickLoginGuest}
                    >
                      Continue as a Guest
                    </div>
                    <div className="page-home-sign-in-privacy-note">
                      * Sign-in data is used purely to display names alongside triggered sounds and to prevent spam, for more details please refer to the <Link className="privacy-policy-link" to="/privacy-policy">privacy policy.</Link>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {isInvalidRoom && <div className="invalid-room-message">Invalid Room ID: {roomId}</div>}
        <Footer firebase={firebase} user={user} />
      </div>
    );
  }
};

const SoundGroup = ({ accent, category, children, width }) => (
  <div
    className="sound-group"
    style={{ width: width || '160px' }}
  >
    <div
      className="sound-group-category"
      style={{ color: `rgba(${accent[0]}, ${accent[1]}, ${accent[2]})` }}
    >
      <div>{startCase(category)}</div>
    </div>
    {children}
  </div>
)

export default withRouter(Home);
