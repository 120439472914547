import React from 'react';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale-subtle.css';

import './index.css';

const SoundButtonSmall = ({
  icon,
  soundId,
  onClickPlay,
  tooltipText
}) => {
  return (
    <Tippy
      content={tooltipText}
      placement="top"
      animation="scale-subtle"
      theme="light"
      arrow={true}
      duration={200}
      delay={[75, 0]}
    >
      <div
        className="sound-button-small"
        onMouseDown={() => onClickPlay(soundId)}
      >
        <div>
          {icon}
        </div>
      </div>
    </Tippy>
  )
};

export default SoundButtonSmall;
