import React, { Fragment } from "react";
import "./index.css";
import { Link, useHistory } from "react-router-dom";

const Footer = ({ user, firebase }) => {
  const history = useHistory();

  const handleClickLogout = () => {
    firebase.auth().signOut();
    history.push('/');
  }

  return (
    <div className="page-footer">
      <Credits />
    </div>
  );
};

const Credits = () => (
  <Fragment>
    <div className="buy-me-a-coffee-container container-sm">
      <a className="buy-me-a-coffee-link" href="https://www.buymeacoffee.com/nafeunasir">
        Want To Support The App? Buy Me A Coffee ☕️😊
      </a>
    </div>
    <div className="page-footer-credits">
      <Link className="privacy-policy-link" to="/privacy-policy">Privacy Policy</Link> 🐝 Bee Womp was made with ❤️ by <a className="author-link" href="https://nafeu.com/about">Nafeu.</a> <div className="buy-me-a-coffee-container container-lg"><a className="buy-me-a-coffee-link" href="https://www.buymeacoffee.com/nafeunasir">Want To Support The App? Buy Me A Coffee ☕️😊</a></div>
    </div>
  </Fragment>
)

export default Footer;
