import React, { useState, Fragment } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";

import "./index.css";

const ONE_SECOND = 1000;

const MOUSE_OUT_DEBOUNCE_MS = 200;

const RoomInfo = ({ roomId, username, firebase }) => {
  const history = useHistory();

  const handleClickLogout = () => {
    firebase.auth().signOut();
    history.push('/');
  }

  const [isCopied, setIsCopied] = useState(false);
  const [isHoveringSession, setIsHoveringSession] = useState(false);
  const [isHoveringAccount, setIsHoveringAccount] = useState(false);

  const handleClickCopy = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, ONE_SECOND)
  }

  const handleOnMouseEnterAccount = () => {
    setIsHoveringAccount(true);
  }

  const handleOnMouseLeaveAccount = debounce(
    () => setIsHoveringAccount(false), MOUSE_OUT_DEBOUNCE_MS
  )

  const handleOnMouseEnterSession = () => {
    setIsHoveringSession(true);
  }

  const handleOnMouseLeaveSession = debounce(
    () => setIsHoveringSession(false), MOUSE_OUT_DEBOUNCE_MS
  )

  return (
    <div className="room-info">
      <div className="settings-header">Public Session URL:</div>
      <CopyToClipboard
        text={window.location.href}
        onCopy={handleClickCopy}
      >
        <div
          className="room-info-display"
          onMouseEnter={handleOnMouseEnterSession}
          onMouseLeave={handleOnMouseLeaveSession}
        >
          {isCopied ? 'Copied To Clipboard.' : (
            isHoveringSession ? (
              <Fragment>
                <span className="faded-display">Click To Copy</span>
              </Fragment>
            ) : (
              <Fragment>
                <span className="faded-display">beewomp.me/</span>{roomId}
              </Fragment>
            )
          )}
        </div>
      </CopyToClipboard>
      <div className="settings-header">Logged In As:</div>
      <div
        className="account-info-display"
        onMouseEnter={handleOnMouseEnterAccount}
        onMouseLeave={handleOnMouseLeaveAccount}
        onClick={handleClickLogout}
      >
        {
          isHoveringAccount ? (
            <Fragment>
              <span className="faded-display">Click To Logout</span>
            </Fragment>
          ) : (
            <Fragment>
              {username}
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

export default RoomInfo;
