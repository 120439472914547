import React from "react";
import Slider from 'rc-slider';
import "./index.css";
import 'rc-slider/assets/index.css';

const VolumeControl = ({ volume, onChangeVolume }) => {
  return (
    <div className="volume-control">
      <div className="settings-header">Volume</div>
      <div className="volume-control-input">
        <Slider
          railStyle={{ backgroundColor: '#485460', height: 4 }}
          trackStyle={{ backgroundColor: '#ffc048', height: 4 }}
          handleStyle={{
            borderColor: '#ffc048',
            backgroundColor: '#ffc048',
            opacity: 1
          }}
          onChange={onChangeVolume}
          value={volume}
        />
      </div>
    </div>
  );
};

export default VolumeControl;

